var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BT-Blade-Items',{attrs:{"addBladeName":"batch-archive","bladesData":_vm.bladesData,"bladeName":"batch-archives","canDelete":false,"canExportCSV":"","canSearchLocal":"","getParams":function (bladeData) { return { includeDetails: false, locationID: bladeData.data.id, productID: bladeData.data.productID }},"headers":[
        { text: 'Product', value: 'productID', navigation: 'public-products', itemText: 'productName', single: true, useLocalCache: true, csv: true },
        { text: 'Batchcode', value: 'batchcode', csv: true, subtitle: 1, searchable: true },
        { text: 'DOM', value: 'dom', textFilter: 'toShortDate', csv: true, subtitle: 2, prefix: 'DOM: ' },
        { text: 'EXP', value: 'exp', textFilter: 'toShortDate', csv: true },
        { text: 'Quantity', value: 'quantity', textFilter: 'toDisplayNumber', breakdown: true, isIcon: true, csv: true }],"navigation":"batch-archives","title":"Batch Archives","useServerPagination":""},scopedSlots:_vm._u([{key:"itemActions",fn:function(ref){
        var item = ref.item;
return [_c('BT-Btn',{attrs:{"bladeName":"batch-tracking","id":item.id,"leftIcon":"mdi-radar","small":"","title":"Batch tracking"}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }